var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { ref: "headerQuery", staticClass: "fie_wrap layout-container" },
    [
      _c(
        "el-aside",
        { staticClass: "fie_left" },
        [
          _c(
            "el-form",
            {
              ref: "editBean",
              attrs: { model: _vm.editBean, "label-width": "80px" },
            },
            [
              _c(
                "el-container",
                { staticClass: "fie_left_top" },
                [
                  _c(
                    "el-aside",
                    { staticClass: "fie_left_top_left" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "border-left": "5px solid #19B319",
                                "padding-left": "10px",
                                "line-height": "20px",
                              },
                            },
                            [_vm._v("个人信息")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 微信号 ")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_content",
                              attrs: { span: 18 },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.originShowBean.wxNumber) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 真实姓名 ")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_content",
                              attrs: { span: 18 },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.originShowBean.realName) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 所在地区 ")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_content",
                              attrs: { span: 18 },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.originShowBean.location) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 所在单位 ")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_content",
                              attrs: { span: 18 },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.originShowBean.hospitalName) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 职业 ")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_content",
                              attrs: { span: 18 },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.originShowBean.occupationName) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 职称 ")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_content",
                              attrs: { span: 18 },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.originShowBean.titleName) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 工牌 ")]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_content",
                              attrs: { span: 18 },
                            },
                            [
                              _c(
                                "el-image",
                                {
                                  staticStyle: {
                                    width: "210px",
                                    height: "130px",
                                    "border-radius": "10px",
                                  },
                                  attrs: {
                                    src: _vm.originShowBean.workCardUrl,
                                    fit: _vm.fit,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewWorkCardClick(
                                        _vm.originShowBean.workCardUrl
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      staticStyle: {
                                        width: "210px",
                                        height: "130px",
                                        "line-height": "130px",
                                        "text-align": "center",
                                        "border-radius": "10px",
                                        background: "#F2F2F2",
                                      },
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "el-icon-picture-outline",
                                          staticStyle: {
                                            color: "#999999",
                                            "font-size": "12px",
                                          },
                                        },
                                        [_vm._v(" 暂无工牌 ")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "personalInfo" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "personalInfo_label",
                              attrs: { span: 6 },
                            },
                            [_vm._v(" 主攻方向 ")]
                          ),
                          _vm.originShowBean.mainDirectionStrList
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "personalInfo_content",
                                  attrs: { span: 18 },
                                },
                                _vm._l(
                                  _vm.originShowBean.mainDirectionStrList,
                                  function (item) {
                                    return _c(
                                      "ul",
                                      { staticClass: "zgfx_ul" },
                                      [
                                        _c("li", { staticClass: "zgfx_li" }, [
                                          _vm._v(_vm._s(item)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.originShowBean.caseDesc
                        ? _c(
                            "el-row",
                            {
                              staticClass: "personalInfo",
                              staticStyle: { "padding-left": "30px" },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "personalInfo_label",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: _vm.lookAntiInfectionCaseFn,
                                      },
                                    },
                                    [_vm._v("查看抗感染病例")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticClass: "fie_left_top_right" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "border-left": "5px solid #19B319",
                                    "padding-left": "10px",
                                    "line-height": "20px",
                                  },
                                },
                                [_vm._v(" 校正信息 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#6CCD3B",
                                    color: "#FFFFFF",
                                  },
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.lookGroupInfoClick()
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "真实姓名" } },
                            [
                              _vm.isPersonInfo
                                ? _c("el-input", {
                                    staticStyle: { width: "200px" },
                                    model: {
                                      value: _vm.editBean.realName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.editBean, "realName", $$v)
                                      },
                                      expression: "editBean.realName",
                                    },
                                  })
                                : _c(
                                    "span",
                                    { staticClass: "personalInfo_content" },
                                    [_vm._v(_vm._s(_vm.editBean.realName))]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "所在地区" } },
                            [
                              _vm.isPersonInfo
                                ? _c("el-cascader", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      props: _vm.props,
                                      options: _vm.addressList,
                                    },
                                    on: { change: _vm.addressChange },
                                    model: {
                                      value: _vm.region,
                                      callback: function ($$v) {
                                        _vm.region = $$v
                                      },
                                      expression: "region",
                                    },
                                  })
                                : _c(
                                    "span",
                                    { staticClass: "personalInfo_content" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.editBean.provinceName) +
                                          " " +
                                          _vm._s(_vm.editBean.cityName) +
                                          " " +
                                          _vm._s(_vm.editBean.districtName)
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "所在单位" } },
                            [
                              _vm.isPersonInfo
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        "popper-append-to-body": false,
                                        filterable: "",
                                        remote: "",
                                        size: "small",
                                        clearable: "",
                                        "collapse-tags": "",
                                        "reserve-keyword": "",
                                        placeholder: "请输入所在医院",
                                        "remote-method": _vm.remoteMethodSL,
                                        loading: _vm.loading,
                                      },
                                      model: {
                                        value: _vm.editBean.hospitalCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editBean,
                                            "hospitalCode",
                                            $$v
                                          )
                                        },
                                        expression: "editBean.hospitalCode",
                                      },
                                    },
                                    _vm._l(_vm.hospitalList, function (item) {
                                      return _c("el-option", {
                                        key: item.substanceId + item.name,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "personalInfo_content" },
                                    [_vm._v(_vm._s(_vm.editBean.hospitalCode))]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "职业" } },
                            [
                              _vm.isPersonInfo
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请选择活动区域" },
                                      model: {
                                        value: _vm.editBean.occupationId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editBean,
                                            "occupationId",
                                            $$v
                                          )
                                        },
                                        expression: "editBean.occupationId",
                                      },
                                    },
                                    _vm._l(_vm.occupationList, function (item) {
                                      return _c("el-option", {
                                        key: "occupationList" + item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "personalInfo_content" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.editBean.occupationName)
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "职称" } },
                            [
                              _vm.isPersonInfo
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请选择活动区域" },
                                      model: {
                                        value: _vm.editBean.titleId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editBean, "titleId", $$v)
                                        },
                                        expression: "editBean.titleId",
                                      },
                                    },
                                    _vm._l(_vm.titleList, function (item) {
                                      return _c("el-option", {
                                        key: "titleList" + item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "personalInfo_content" },
                                    [_vm._v(_vm._s(_vm.editBean.titleName))]
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "工牌" } },
                            [
                              _vm.isExchange == false
                                ? _c(
                                    "el-image",
                                    {
                                      staticStyle: {
                                        width: "210px",
                                        height: "130px",
                                        "border-radius": "10px",
                                      },
                                      attrs: {
                                        src: _vm.editBean.workCardUrl,
                                        fit: _vm.fit,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.previewWorkCardClick(
                                            _vm.editBean.workCardUrl
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          staticStyle: {
                                            width: "210px",
                                            height: "130px",
                                            "line-height": "130px",
                                            "text-align": "center",
                                            "border-radius": "10px",
                                            background: "#F2F2F2",
                                          },
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "el-icon-picture-outline",
                                              staticStyle: {
                                                color: "#999999",
                                                "font-size": "12px",
                                              },
                                            },
                                            [_vm._v(" 暂无工牌 ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isPersonInfo && _vm.isExchange == false
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        right: "0px",
                                        bottom: "50px",
                                      },
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exchangeImg()
                                        },
                                      },
                                    },
                                    [_vm._v("更换")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-upload",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isExchange == true,
                                      expression: "isExchange == true",
                                    },
                                  ],
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    action: "",
                                    "show-file-list": false,
                                    "http-request": _vm.uploadFile,
                                    "on-success": _vm.handleAvatarSuccess,
                                    "before-upload": _vm.beforeAvatarUpload,
                                  },
                                },
                                [
                                  _vm.imageUrl
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: { src: _vm.imageUrl },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "主攻方向" } },
                            [
                              _c(
                                "el-select",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPersonInfo,
                                      expression: "isPersonInfo",
                                    },
                                  ],
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择活动区域",
                                  },
                                  model: {
                                    value: _vm.editBean.mainDirectionIdList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editBean,
                                        "mainDirectionIdList",
                                        $$v
                                      )
                                    },
                                    expression: "editBean.mainDirectionIdList",
                                  },
                                },
                                _vm._l(_vm.directionList, function (item) {
                                  return _c("el-option", {
                                    key: "directionList" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "section",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.originShowBean.mainDirectionStrList,
                                      expression:
                                        "originShowBean.mainDirectionStrList",
                                    },
                                  ],
                                },
                                _vm._l(
                                  _vm.editBean.mainDirectionStrList,
                                  function (item) {
                                    return _c(
                                      "ul",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isPersonInfo,
                                            expression: "!isPersonInfo",
                                          },
                                        ],
                                        staticClass: "zgfx_ul",
                                      },
                                      [
                                        _c("li", { staticClass: "zgfx_li" }, [
                                          _vm._v(_vm._s(item)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-footer",
                { staticClass: "fie_left_bottom" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "border-left": "5px solid #19B319",
                            "padding-left": "10px",
                            "line-height": "20px",
                          },
                        },
                        [_vm._v("个人信息")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { margin: "20px" },
                          attrs: { label: "备注" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              disabled: !_vm.isPersonInfo,
                            },
                            model: {
                              value: _vm.editBean.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.editBean, "remark", $$v)
                              },
                              expression: "editBean.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _vm.isPersonInfo
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                background: "#6CCD3B",
                                color: "#FFFFFF",
                              },
                              attrs: { size: "mini" },
                              on: { click: _vm.updataPersonalInfoFn },
                            },
                            [_vm._v("保存")]
                          )
                        : _vm._e(),
                      _vm.isPersonInfo
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.cancelPersonalInfoFn },
                            },
                            [_vm._v("取消")]
                          )
                        : _vm._e(),
                      !_vm.isPersonInfo
                        ? _c(
                            "el-button",
                            {
                              staticStyle: {
                                background: "#6CCD3B",
                                color: "#FFFFFF",
                              },
                              attrs: { size: "mini" },
                              on: { click: _vm.motifyPersonInfoClick },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "fie_right" },
        [
          _c("el-header", { staticClass: "fie_right_top" }, [
            _c(
              "section",
              { staticClass: "fie_right_top_section" },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "border-left": "5px solid #19B319",
                          "padding-left": "10px",
                          "line-height": "20px",
                        },
                      },
                      [_vm._v("粉丝关注信息")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          "label-position": _vm.labelPosition,
                          "label-width": "80px",
                          model: _vm.fansInfoForm,
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "OpenID" } },
                              [
                                _vm.isFansInfo
                                  ? _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { disabled: "", clearable: "" },
                                      model: {
                                        value: _vm.fansInfoForm.openId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.fansInfoForm,
                                            "openId",
                                            $$v
                                          )
                                        },
                                        expression: "fansInfoForm.openId",
                                      },
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "personalInfo_content" },
                                      [_vm._v(_vm._s(_vm.fansInfoForm.openId))]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "微信号" } },
                              [
                                _vm.isFansInfo
                                  ? _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.fansInfoForm.wxNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.fansInfoForm,
                                            "wxNumber",
                                            $$v
                                          )
                                        },
                                        expression: "fansInfoForm.wxNumber",
                                      },
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "personalInfo_content" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.fansInfoForm.wxNumber)
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "昵称" } },
                              [
                                _vm.isFansInfo
                                  ? _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { clearable: "" },
                                      model: {
                                        value: _vm.fansInfoForm.nickName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.fansInfoForm,
                                            "nickName",
                                            $$v
                                          )
                                        },
                                        expression: "fansInfoForm.nickName",
                                      },
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "personalInfo_content" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.fansInfoForm.nickName)
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { margin: "0px" },
                                attrs: { label: "推荐人" },
                              },
                              [
                                _vm.isFansInfo
                                  ? _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择推荐人",
                                        },
                                        model: {
                                          value:
                                            _vm.fansInfoForm.recommenderOpenId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fansInfoForm,
                                              "recommenderOpenId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "fansInfoForm.recommenderOpenId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.recommenderOpenIdList,
                                        function (item) {
                                          return _c("el-option", {
                                            key:
                                              "recommenderOpenIdList" +
                                              item.openId,
                                            attrs: {
                                              label: item.realName,
                                              value: item.openId,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "personalInfo_content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fansInfoForm.recommenderName
                                          )
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "关注时间" } },
                              [
                                _vm.isFansInfo
                                  ? _c("el-date-picker", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        disabled: "",
                                        type: "date",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: _vm.fansInfoForm.subscribeTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.fansInfoForm,
                                            "subscribeTime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "fansInfoForm.subscribeTime",
                                      },
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "personalInfo_content" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.fansInfoForm.subscribeTime)
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "关注状态" } },
                              [
                                _vm.isFansInfo
                                  ? _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          disabled: "",
                                          placeholder: "选择状态",
                                        },
                                        model: {
                                          value: _vm.fansInfoForm.subscribe,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.fansInfoForm,
                                              "subscribe",
                                              $$v
                                            )
                                          },
                                          expression: "fansInfoForm.subscribe",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "关注中",
                                            value: "1",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "取消关注",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "未知", value: "" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "personalInfo_content" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "focusStatus" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("subscribeChoice")(
                                                  _vm.fansInfoForm.subscribe
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "取关时间" } },
                              [
                                _vm.isFansInfo
                                  ? _c("el-date-picker", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        disabled: "",
                                        type: "date",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: _vm.fansInfoForm.unsubscribeTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.fansInfoForm,
                                            "unsubscribeTime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "fansInfoForm.unsubscribeTime",
                                      },
                                    })
                                  : _c(
                                      "span",
                                      { staticClass: "personalInfo_content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.fansInfoForm.unsubscribeTime
                                          )
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _vm.isFansInfo
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              background: "#6CCD3B",
                              color: "#FFFFFF",
                            },
                            attrs: { size: "mini" },
                            on: { click: _vm.updataSubscribeInfoFn },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                    _vm.isFansInfo
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.cancelSubscribeInfoFn },
                          },
                          [_vm._v("取消")]
                        )
                      : _vm._e(),
                    !_vm.isFansInfo
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              background: "#6CCD3B",
                              color: "#FFFFFF",
                            },
                            attrs: { size: "mini" },
                            on: { click: _vm.motifyFansInfo },
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-main", { ref: "barparent", staticClass: "fie_right_bottom" }, [
            _c(
              "section",
              { staticClass: "fie_right_bottom_section" },
              [
                _c(
                  "el-header",
                  { staticClass: "fie_right_bottom_header" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "border-left": "5px solid #19B319",
                              "padding-left": "10px",
                              "line-height": "20px",
                            },
                            attrs: { span: 12 },
                          },
                          [_vm._v("群信息")]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 12 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { padding: "0px" },
                                attrs: { type: "text" },
                                on: { click: _vm.addGroupInfo },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-plus-outline",
                                }),
                                _vm._v("添加群信息"),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-main",
                  { staticClass: "fie_right_bottom_main" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "oraTableData",
                        attrs: {
                          border: "",
                          stripe: "",
                          height: _vm.tableHeight,
                          "header-cell-style": { "text-align": "center" },
                          "cell-style": { "text-align": "center" },
                          data: _vm.applyList,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "群名",
                            prop: "groupName",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "状态",
                            prop: "groupStatusStr",
                            align: "center",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "申请时间",
                            align: "center",
                            prop: "createTime",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseTime(scope.row.createTime)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "进群时间",
                            align: "center",
                            prop: "entryTime",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.parseTime(scope.row.entryTime))
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "退群时间",
                            align: "center",
                            prop: "signOutTime",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseTime(scope.row.signOutTime)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "退群类型",
                            prop: "signOutTypeStr",
                            align: "center",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "退群备注",
                            prop: "remark",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            "class-name": "small-padding fixed-width",
                            fixed: "right",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.groupStatus === 1
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#6CCD3B" },
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.passGroupInfo(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("通过")]
                                      )
                                    : _vm._e(),
                                  scope.row.groupStatus === 2
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#FF3333" },
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reBackGroupInfo(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("撤销")]
                                      )
                                    : _vm._e(),
                                  scope.row.groupStatus === 2 ||
                                  scope.row.groupStatus === 4 ||
                                  scope.row.groupStatus === 5
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#2D5AFA" },
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.enterGroupInfo(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("进群")]
                                      )
                                    : _vm._e(),
                                  scope.row.groupStatus === 6
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#FF3333" },
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.leaveGroupClick(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("退群")]
                                      )
                                    : _vm._e(),
                                  scope.row.groupStatus === 1
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#FF3333" },
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.refuseReasonClick(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("拒绝")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.isResult === false
                          ? _c("template", { slot: "empty" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/no-booking.svg"),
                                  alt: "",
                                },
                              }),
                              _c("p", [_vm._v("暂无数据")]),
                            ])
                          : _c("template", { slot: "empty" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/no-left-data.svg"),
                                  alt: "",
                                },
                              }),
                              _c("p", [
                                _vm._v("未查询到结果，请修改查询条件重试！"),
                              ]),
                            ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: _vm.groupInfoTitle,
            visible: _vm.isAddGroupInfo,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isAddGroupInfo = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formAddGroupInfo",
              attrs: { model: _vm.formAddGroupInfo, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "群名称", prop: "remark" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "选择群名称",
                        disabled: _vm.isAddShow,
                      },
                      model: {
                        value: _vm.formAddGroupInfo.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formAddGroupInfo, "groupId", $$v)
                        },
                        expression: "formAddGroupInfo.groupId",
                      },
                    },
                    _vm._l(_vm.groupInfoList, function (item) {
                      return _c("el-option", {
                        key: "groupInfoList" + item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "进群时间", prop: "remark" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.formAddGroupInfo.entryTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formAddGroupInfo, "entryTime", $$v)
                      },
                      expression: "formAddGroupInfo.entryTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      staticStyle: { background: "#6CCD3B", color: "#FFFFFF" },
                      attrs: { size: "mini" },
                      on: { click: _vm.groupAddFn },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "failClass",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.isAddGroupInfo = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "拒绝原因",
            visible: _vm.isRefuseReason,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isRefuseReason = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "formRefuseReason", attrs: { model: _vm.formRefuseReason } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.formRefuseReason.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formRefuseReason, "remark", $$v)
                      },
                      expression: "formRefuseReason.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      staticStyle: { background: "#6CCD3B", color: "#FFFFFF" },
                      attrs: { size: "mini" },
                      on: { click: _vm.refuseReasonConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "failClass",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.isRefuseReason = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "标记退群",
            visible: _vm.isLeaveGroup,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isLeaveGroup = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "formLeaveGroup", attrs: { model: _vm.formLeaveGroup } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "退群类型",
                    prop: "groupStatus",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formLeaveGroup.groupStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formLeaveGroup, "groupStatus", $$v)
                        },
                        expression: "formLeaveGroup.groupStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("主动退群"),
                      ]),
                      _c("el-radio", { attrs: { label: 5 } }, [_vm._v("踢出")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "退群时间",
                    prop: "signOutTime",
                    "label-width": "80px",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.formLeaveGroup.signOutTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLeaveGroup, "signOutTime", $$v)
                      },
                      expression: "formLeaveGroup.signOutTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.formLeaveGroup.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLeaveGroup, "remark", $$v)
                      },
                      expression: "formLeaveGroup.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      staticStyle: { background: "#6CCD3B", color: "#FFFFFF" },
                      attrs: { size: "mini" },
                      on: { click: _vm.groupApplyFn },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "failClass",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.isLeaveGroup = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "查看抗感染病例",
            visible: _vm.isAntiInfection,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isAntiInfection = $event
            },
          },
        },
        [
          _c(
            "section",
            {
              staticStyle: {
                height: "400px",
                overflow: "auto",
                "text-indent": "2em",
                "font-size": "14px",
              },
            },
            [_vm._v(" " + _vm._s(_vm.originShowBean.caseDesc) + " ")]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "查看历史用户信息",
            visible: _vm.isHistoryGroupInfo,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isHistoryGroupInfo = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "line-height": "40px!important" },
              attrs: { id: "lookInfo" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "border-left": "5px solid #19B319",
                        "padding-left": "10px",
                        "line-height": "20px",
                      },
                    },
                    [_vm._v("匹配历史用户信息")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 序号 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [_vm._v(" " + _vm._s(_vm.historyShowBean.id) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 真实姓名 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.realName) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 所在地区 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.location) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 所在单位 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.hospitalName) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 职业 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.historyShowBean.occupationName) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 职称 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.titleName) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 主攻方向 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.historyShowBean.medicalDirectionName) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 群信息 ")]
                  ),
                  _vm.historyShowBean && _vm.historyShowBean.groupStrList
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        _vm._l(
                          _vm.historyShowBean.groupStrList,
                          function (item) {
                            return _c(
                              "ul",
                              {
                                staticClass: "zgfx_ul",
                                staticStyle: { float: "left" },
                              },
                              [
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      padding: "0px 5px",
                                      height: "30px",
                                      "line-height": "30px",
                                      "/*background": "#FAEBFF",
                                      "*/\n                /*border":
                                        "1px dashed #CF75EB",
                                      "*/\n                text-align":
                                        "center",
                                      "border-radius": "4px",
                                      "font-size": "14px",
                                      "font-weight": "400",
                                      color: "#19B319",
                                      "margin-right": "5px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "预览工牌",
            visible: _vm.isPreviewWorkCard,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isPreviewWorkCard = $event
            },
          },
        },
        [
          _c(
            "el-image",
            {
              staticStyle: {
                "border-radius": "10px",
                "max-width": "100%",
                "max-height": "500px",
                overflow: "auto",
              },
              attrs: { src: _vm.preViewImageUrl, fit: _vm.fit },
            },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  staticStyle: {
                    width: "210px",
                    height: "130px",
                    "line-height": "130px",
                    "text-align": "center",
                    "border-radius": "10px",
                    background: "#F2F2F2",
                  },
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "el-icon-picture-outline",
                      staticStyle: { color: "#999999", "font-size": "12px" },
                    },
                    [_vm._v(" 暂无工牌 ")]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }