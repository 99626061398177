import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 微信用户列表 群申请时间排序字段 groupApplyTime
export function list(data) {
  return request({
    url: '/thys-api/manage/wx/user/list',
    method: 'get',
    params: data
  })
}
// 个人信息查询
export function personalInfo(id) {
  return request({
    url: '/thys-api/manage/wx/user/personal/info/' + id,
    method: 'get'
  })
}
// 个人信息更新
export function updataPersonalInfo(data) {
  return request({
    url: '/thys-api/manage/wx/user/personal/info',
    method: 'put',
    data: data
  })
}
// 群信息查询
export function groupInfo(id) {
  return request({
    url: '/thys-api/manage/wx/user/group/info/' + id,
    method: 'get'
  })
}
// 新增微信用户群信息
export function groupAdd(data) {
  return request({
    url: '/thys-api/manage/wx/user/group/add',
    method: 'post',
    data: data
  })
}
// 粉丝关注信息查询
export function subscribeInfo(id) {
  return request({
    url: '/thys-api/manage/wx/user/subscribe/info/' + id,
    method: 'get'
  })
}
// 粉丝关注信息更新
export function updataSubscribeInfo(data) {
  return request({
    url: '/thys-api/manage/wx/user/subscribe/info',
    method: 'put',
    data: data
  })
}
// 删除
export function fansDelete(id) {
  return request({
    url: '/thys-api/manage/wx/user/delete/' + praseStrEmpty(id),
    method: 'put'
  })
}
// 职业列表
export function occupation() {
  return request({
    url: '/thys-api/dict/occupation',
    method: 'get'
  })
}
// 职称列表
export function title() {
  return request({
    url: '/thys-api/dict/title',
    method: 'get'
  })
}
// 主攻方向
export function direction() {
  return request({
    url: '/thys-api/dict/medical/direction',
    method: 'get'
  })
}
// 工牌等文件上传
// bucket = openId + 'edit' + 文件名
// key = 文件名
export function serviceUpload(data) {
  return request({
    url: '/thys-api/file/service',
    method: 'post',
    data: data
  })
}
// 微信群列表
export function groupList(data) {
  return request({
    url: '/thys-api/wx/group/list',
    method: 'get',
    params: data
  })
}
// 推荐人list
// /manage/wx/user/recommenderOpenId
export function recommenderOpenId() {
  return request({
    url: '/thys-api/manage/wx/user/recommenderOpenId',
    method: 'get'
  })
}
// 通过-拒绝进群
export function groupApply(data) {
  return request({
    url: '/thys-api/manage/wx/user/group/apply',
    method: 'put',
    data: data
  })
}
// 全国省市区联动数据获取
export function getAddressApi(data) {
  return request({
    url: '/thys-api/dict/region',
    method: 'get',
    params: data
  })
}
// 医院列表
export function hospital(searchValue) {
  return request({
    url: '/thys-api/dict/hospital',
    method: 'get',
    params: {searchValue:searchValue}
  })
}
