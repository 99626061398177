<template>
    <el-container class="fie_wrap layout-container" ref="headerQuery">
      <el-aside class="fie_left">
        <el-form ref="editBean" :model="editBean" label-width="80px">
        <el-container class="fie_left_top">
          <el-aside class="fie_left_top_left">
            <el-row>
              <el-col style="border-left: 5px solid #19B319;padding-left: 10px;line-height: 20px;">个人信息</el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                微信号
              </el-col>
              <el-col :span="18" class="personalInfo_content">
                {{ originShowBean.wxNumber }}
              </el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                真实姓名
              </el-col>
              <el-col :span="18" class="personalInfo_content">
                {{ originShowBean.realName }}
              </el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                所在地区
              </el-col>
              <el-col :span="18" class="personalInfo_content">
                {{ originShowBean.location }}
              </el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                所在单位
              </el-col>
              <el-col :span="18" class="personalInfo_content">
                {{ originShowBean.hospitalName }}
<!--                <br/>-->
<!--                <span class="ipAddress">IP地址所在地：江苏 南通市</span>-->
              </el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                职业
              </el-col>
              <el-col :span="18" class="personalInfo_content">
                {{ originShowBean.occupationName }}
              </el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                职称
              </el-col>
              <el-col :span="18" class="personalInfo_content">
                {{ originShowBean.titleName }}
              </el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                工牌
              </el-col>
              <el-col :span="18" class="personalInfo_content">
                <el-image style="width: 210px;height: 130px;border-radius: 10px;"
                    :src="originShowBean.workCardUrl"
                    :fit="fit"
                    @click="previewWorkCardClick(originShowBean.workCardUrl)">
                  <div slot="error" class="image-slot"
                       style="width: 210px;height: 130px;line-height:130px; text-align:center;border-radius: 10px;background: #F2F2F2;">
                    <i class="el-icon-picture-outline"
                       style="color: #999999;font-size: 12px;">
                      暂无工牌
                    </i>
                  </div>
                </el-image>
              </el-col>
            </el-row>
            <el-row class="personalInfo">
              <el-col :span="6" class="personalInfo_label">
                主攻方向
              </el-col>
              <el-col :span="18" class="personalInfo_content" v-if="originShowBean.mainDirectionStrList">
                <ul class="zgfx_ul" v-for="item in originShowBean.mainDirectionStrList">
                  <li class="zgfx_li">{{ item }}</li>
                </ul>
              </el-col>
            </el-row>
<!--            style="background:#6CCD3B;color: #FFFFFF;"-->
            <el-row
              v-if="originShowBean.caseDesc"
              class="personalInfo" style="padding-left:30px;">
              <el-col :span="6" class="personalInfo_label">
                <el-button size="mini"
                           type="text"
                            @click="lookAntiInfectionCaseFn">查看抗感染病例</el-button>
              </el-col>
            </el-row>
          </el-aside>
          <el-main class="fie_left_top_right">
            <el-row>
              <el-col>
                <span
                  style="border-left: 5px solid #19B319;padding-left: 10px;line-height: 20px;">
                  校正信息
                </span>
                <el-button
                  size="mini"
                  style="background:#6CCD3B;color: #FFFFFF;"
                  @click="lookGroupInfoClick()"
                >查看</el-button>
              </el-col>
            </el-row>
            <section>
                <el-form-item label="真实姓名">
                  <el-input v-if="isPersonInfo" v-model="editBean.realName" style="width: 200px;"></el-input>
                  <span v-else class="personalInfo_content">{{ editBean.realName }}</span>
                </el-form-item>
                <el-form-item label="所在地区">
                  <el-cascader
                    v-if="isPersonInfo"
                    :props="props"
                    :options="addressList"
                    v-model="region"
                    style="width: 200px;"
                    @change="addressChange"/>
                  <span v-else class="personalInfo_content">{{ editBean.provinceName }} {{ editBean.cityName }} {{ editBean.districtName }}</span>
                </el-form-item>
                <el-form-item label="所在单位">
                  <el-select
                    v-if="isPersonInfo"
                    :popper-append-to-body="false"
                    v-model="editBean.hospitalCode"
                    filterable
                    remote
                    size="small"
                    clearable
                    collapse-tags
                    reserve-keyword
                    placeholder="请输入所在医院"
                    style="width: 200px;"
                    :remote-method="remoteMethodSL"
                    :loading="loading">
                    <el-option
                      v-for="item in hospitalList"
                      :key="item.substanceId+item.name"
                      :label="item.name"
                      :value="item.code">
                    </el-option>
                  </el-select>
                  <span v-else class="personalInfo_content">{{ editBean.hospitalCode }}</span>
                </el-form-item>
                <el-form-item label="职业">
                  <el-select v-if="isPersonInfo" v-model="editBean.occupationId"
                             style="width: 200px;"
                             placeholder="请选择活动区域">
                    <el-option
                      v-for="item in occupationList"
                      :key="'occupationList'+item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                  <span v-else class="personalInfo_content">{{ editBean.occupationName }}</span>
                </el-form-item>
                <el-form-item label="职称">
                  <el-select v-if="isPersonInfo" v-model="editBean.titleId"
                             style="width: 200px;"
                             placeholder="请选择活动区域">
                    <el-option
                      v-for="item in titleList"
                      :key="'titleList'+item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                  <span v-else class="personalInfo_content">{{ editBean.titleName }}</span>
                </el-form-item>
                <el-form-item label="工牌">
                  <el-image style="width: 210px;height: 130px;border-radius: 10px;"
                            v-if="isExchange == false"
                            :src="editBean.workCardUrl"
                            :fit="fit"
                            @click="previewWorkCardClick(editBean.workCardUrl)">
                    <div slot="error" class="image-slot"
                         style="width: 210px;height: 130px;line-height:130px; text-align:center;border-radius: 10px;background: #F2F2F2;">
                      <i class="el-icon-picture-outline"
                        style="color: #999999;font-size: 12px;">
                        暂无工牌
                      </i>
                    </div>
                  </el-image>
                  <el-button v-if="isPersonInfo && isExchange == false" size="mini" style="position: absolute;right: 0px;bottom:50px;"@click="exchangeImg()">更换</el-button>
                  <el-upload
                    v-show="isExchange == true"
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :http-request="uploadFile"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item label="主攻方向">
                  <el-select v-show="isPersonInfo" v-model="editBean.mainDirectionIdList" multiple placeholder="请选择活动区域">
                    <el-option
                      v-for="item in directionList"
                      :key="'directionList'+item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <section v-show="originShowBean.mainDirectionStrList">
                    <ul v-show="!isPersonInfo" class="zgfx_ul" v-for="item in editBean.mainDirectionStrList">
                      <li class="zgfx_li">{{ item }}</li>
                    </ul>
                  </section>
                </el-form-item>
            </section>
          </el-main>
        </el-container>
        <el-footer class="fie_left_bottom">
          <el-row>
            <el-col style="border-left: 5px solid #19B319;padding-left: 10px;line-height: 20px;">个人信息</el-col>
          </el-row>
          <el-row>
            <el-form-item label="备注" style="margin: 20px;">
              <el-input type="textarea" v-model="editBean.remark" :disabled="!isPersonInfo"></el-input>
            </el-form-item>
          </el-row>
          <el-row style="text-align: center;">
            <el-button size="mini"
                       v-if="isPersonInfo"
                       style="background:#6CCD3B;color: #FFFFFF;" @click="updataPersonalInfoFn">保存</el-button>
            <el-button size="mini"
                       v-if="isPersonInfo" @click="cancelPersonalInfoFn">取消</el-button>
            <el-button size="mini"
                       v-if="!isPersonInfo"
                       style="background:#6CCD3B;color: #FFFFFF;" @click="motifyPersonInfoClick">修改</el-button>
          </el-row>
        </el-footer>
        </el-form>
      </el-aside>
      <el-container class="fie_right">
        <el-header class="fie_right_top">
          <section class="fie_right_top_section">
            <el-row>
              <el-col style="border-left: 5px solid #19B319;padding-left: 10px;line-height: 20px;">粉丝关注信息</el-col>
            </el-row>
            <el-row>
              <el-form :label-position="labelPosition" label-width="80px" :model="fansInfoForm">
                <el-col :span="24">
                  <el-form-item label="OpenID">
                    <el-input v-if="isFansInfo"
                              disabled
                              v-model="fansInfoForm.openId"
                              clearable
                              style="width: 200px;"></el-input>
                    <span v-else class="personalInfo_content">{{ fansInfoForm.openId }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="微信号">
                    <el-input v-if="isFansInfo"
                              v-model="fansInfoForm.wxNumber"
                              clearable
                              style="width: 200px;"></el-input>
                    <span v-else class="personalInfo_content">{{ fansInfoForm.wxNumber }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="昵称">
                    <el-input v-if="isFansInfo"
                              v-model="fansInfoForm.nickName"
                              clearable
                              style="width: 200px;"></el-input>
                    <span v-else class="personalInfo_content">{{ fansInfoForm.nickName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="推荐人" style="margin:0px;">
                    <el-select v-if="isFansInfo"
                               v-model="fansInfoForm.recommenderOpenId"
                               clearable
                               placeholder="请选择推荐人"
                               style="width: 200px;">
                      <el-option
                        v-for="item in recommenderOpenIdList"
                        :key="'recommenderOpenIdList'+item.openId"
                        :label="item.realName"
                        :value="item.openId"
                      />
                    </el-select>
                    <span v-else class="personalInfo_content">{{ fansInfoForm.recommenderName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="关注时间">
                    <el-date-picker
                      disabled
                      v-if="isFansInfo"
                      v-model="fansInfoForm.subscribeTime"
                      type="date"
                      style="width: 200px;"
                      placeholder="选择日期">
                    </el-date-picker>
                    <span v-else class="personalInfo_content">{{ fansInfoForm.subscribeTime }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="关注状态">
                    <el-select disabled v-if="isFansInfo" v-model="fansInfoForm.subscribe" placeholder="选择状态" style="width: 200px;">
                      <el-option label="关注中" value="1"></el-option>
                      <el-option label="取消关注" value="0"></el-option>
                      <el-option label="未知" value=""></el-option>
                    </el-select>
                    <span v-else class="personalInfo_content">
                      <span class="focusStatus">{{ fansInfoForm.subscribe|subscribeChoice }}</span>
                    </span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="取关时间">
                    <el-date-picker
                      disabled
                      v-if="isFansInfo"
                      v-model="fansInfoForm.unsubscribeTime"
                      type="date"
                      style="width: 200px;"
                      placeholder="选择日期">
                    </el-date-picker>
                    <span v-else class="personalInfo_content">{{ fansInfoForm.unsubscribeTime }}</span>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-row>
            <el-row style="text-align: center;">
              <el-button size="mini"
                         v-if="isFansInfo"
                       style="background:#6CCD3B;color: #FFFFFF;" @click="updataSubscribeInfoFn">保存</el-button>
              <el-button size="mini"
                         @click="cancelSubscribeInfoFn"
                         v-if="isFansInfo">取消</el-button>
              <el-button size="mini"
                         v-if="!isFansInfo"
                         style="background:#6CCD3B;color: #FFFFFF;" @click="motifyFansInfo">修改</el-button>
            </el-row>
          </section>
        </el-header>
        <el-main class="fie_right_bottom" ref="barparent">
          <section class="fie_right_bottom_section">
            <el-header class="fie_right_bottom_header">
              <el-row>
                <el-col :span="12" style="border-left:  5px solid #19B319;padding-left: 10px;line-height: 20px;">群信息</el-col>
                <el-col :span="12" style="text-align: right;"><el-button type="text" style="padding: 0px;"
                  @click="addGroupInfo"><i class="el-icon-circle-plus-outline"></i>添加群信息</el-button></el-col>
              </el-row>
            </el-header>
            <el-main class="fie_right_bottom_main">
              <el-table
                ref="oraTableData"
                v-loading="loading"
                border
                stripe
                :height="tableHeight"
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                :data="applyList">
                <el-table-column label="群名" prop="groupName" :show-overflow-tooltip="true"/>
                <el-table-column label="状态" prop="groupStatusStr"  align="center" width="100"/>
                <el-table-column label="申请时间" align="center" prop="createTime" width="180">
                  <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="进群时间" align="center" prop="entryTime" width="180">
                  <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.entryTime) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="退群时间" align="center" prop="signOutTime" width="180">
                  <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.signOutTime) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="退群类型" prop="signOutTypeStr" align="center" width="100">
                </el-table-column>
                <el-table-column label="退群备注" prop="remark" width="100" />
                <el-table-column label="操作" align="center"
                                 class-name="small-padding fixed-width"
                                 fixed="right"
                                 width="120">
                  <template slot-scope="scope">
<!--                    || scope.row.groupStatus	=== 3 审核未通过-->
                    <el-button
                      v-if="scope.row.groupStatus	=== 1 "
                      size="mini"
                      type="text"
                      style="color:#6CCD3B;"
                      @click="passGroupInfo(scope.row)"
                    >通过</el-button>
                    <el-button
                      v-if="scope.row.groupStatus	=== 2"
                      size="mini"
                      type="text"
                      style="color:#FF3333;"
                      @click="reBackGroupInfo(scope.row)"
                    >撤销</el-button>
                    <el-button
                      v-if="scope.row.groupStatus	=== 2||scope.row.groupStatus	=== 4||scope.row.groupStatus	=== 5"
                      size="mini"
                      type="text"
                      style="color:#2D5AFA;"
                      @click="enterGroupInfo(scope.row)"
                    >进群</el-button>
                    <el-button
                      v-if="scope.row.groupStatus	=== 6"
                      size="mini"
                      type="text"
                      style="color:#FF3333;"
                      @click="leaveGroupClick(scope.row)"
                    >退群</el-button>
                    <el-button
                      v-if="scope.row.groupStatus	=== 1"
                      size="mini"
                      type="text"
                      style="color:#FF3333;"
                      @click="refuseReasonClick(scope.row)"
                    >拒绝</el-button>
                  </template>
                </el-table-column>
                <template slot="empty" v-if="isResult === false">
                  <img src="@/assets/images/no-booking.svg" alt="">
                  <p>暂无数据</p>
                </template>
                <template slot="empty" v-else>
                  <img src="@/assets/images/no-left-data.svg" alt="">
                  <p>未查询到结果，请修改查询条件重试！</p>
                </template>
              </el-table>
            </el-main>
<!--            <el-footer class="fie_right_bottom_footer">-->
<!--              <pagination-->
<!--                v-show="total>0"-->
<!--                :total="total"-->
<!--                :page.sync="queryParams.pageNum"-->
<!--                :limit.sync="queryParams.pageSize"-->
<!--                @pagination="getList"-->
<!--              />-->
<!--            </el-footer>-->
          </section>
        </el-main>
      </el-container>
      <!--添加群信息-->
      <el-dialog :title="groupInfoTitle" :visible.sync="isAddGroupInfo" width="500px" append-to-body class="approvalWrap">
        <el-form :model="formAddGroupInfo" ref="formAddGroupInfo" label-width="80px">
          <el-form-item label="群名称" prop="remark">
            <el-select v-model="formAddGroupInfo.groupId" placeholder="选择群名称" :disabled="isAddShow">
              <el-option
                v-for="item in groupInfoList"
                :key="'groupInfoList'+item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="进群时间" prop="remark">
            <el-date-picker
              v-model="formAddGroupInfo.entryTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              style="width: 200px;"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-row>
          <el-col :span="24" style="text-align: center;">
            <el-button @click="groupAddFn"
                       size="mini"
                       style="background:#6CCD3B;color: #FFFFFF;"
                       class="successClass">确定</el-button>
            <el-button @click="isAddGroupInfo=false"
                       size="mini"
                       class="failClass">取消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
      <!--拒绝原因-->
      <el-dialog title="拒绝原因" :visible.sync="isRefuseReason" width="500px" append-to-body class="approvalWrap">
        <el-form :model="formRefuseReason" ref="formRefuseReason">
          <el-form-item label="" prop="remark">
            <el-input type="textarea" v-model="formRefuseReason.remark"></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-col :span="24" style="text-align: center;">
            <el-button @click="refuseReasonConfirm"
                       size="mini"
                       style="background:#6CCD3B;color: #FFFFFF;"
                       class="successClass">确定</el-button>
            <el-button @click="isRefuseReason=false"
                       size="mini"
                       class="failClass">取消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
<!--   标记退群-->
      <el-dialog title="标记退群" :visible.sync="isLeaveGroup" width="500px" append-to-body class="approvalWrap">
        <el-form :model="formLeaveGroup" ref="formLeaveGroup">
          <el-form-item label="退群类型" prop="groupStatus" label-width="80px">
            <el-radio-group v-model="formLeaveGroup.groupStatus" >
              <el-radio :label="4">主动退群</el-radio>
              <el-radio :label="5">踢出</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="退群时间" prop="signOutTime" label-width="80px">
            <el-date-picker
              v-model="formLeaveGroup.signOutTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              style="width: 200px;"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" prop="remark">
            <el-input type="textarea" v-model="formLeaveGroup.remark"></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-col :span="24" style="text-align: center;">
            <el-button @click="groupApplyFn"
                       size="mini"
                       style="background:#6CCD3B;color: #FFFFFF;"
                       class="successClass">确定</el-button>
            <el-button @click="isLeaveGroup=false"
                       size="mini"
                       class="failClass">取消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
      <!--   查看抗感染病例-->
      <el-dialog title="查看抗感染病例" :visible.sync="isAntiInfection" width="500px" append-to-body class="approvalWrap">
        <section style="height: 400px;overflow: auto;text-indent:2em; font-size:14px;">
          {{ originShowBean.caseDesc }}
        </section>
      </el-dialog>
      <!--   查看用户信息-->
      <el-dialog title="查看历史用户信息" :visible.sync="isHistoryGroupInfo" width="500px" append-to-body class="approvalWrap">
        <div style="line-height: 40px!important;" id="lookInfo">
          <el-row>
            <el-col style="border-left: 5px solid #19B319;padding-left: 10px;line-height: 20px;">匹配历史用户信息</el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              序号
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
              {{ historyShowBean.id }}
            </el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              真实姓名
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
              {{ historyShowBean.realName }}
            </el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              所在地区
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
              {{ historyShowBean.location }}
            </el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              所在单位
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
              {{ historyShowBean.hospitalName }}
            </el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              职业
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
              {{ historyShowBean.occupationName }}
            </el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              职称
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
              {{ historyShowBean.titleName }}
            </el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              主攻方向
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
              {{ historyShowBean.medicalDirectionName }}
            </el-col>
          </el-row>
          <el-row class="personalInfo">
            <el-col :span="6" class="personalInfo_label">
              群信息
            </el-col>
            <el-col :span="18" class="personalInfo_content" v-if="historyShowBean && historyShowBean.groupStrList">
              <ul class="zgfx_ul"
                  style="float: left"
                  v-for="item in historyShowBean.groupStrList">
                <li
                  style="display: inline-block;
                padding:0px 5px;
                height: 30px;
                line-height: 30px;
                /*background: #FAEBFF;*/
                /*border: 1px dashed #CF75EB;*/
                text-align: center;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                color: #19B319;
                margin-right: 5px;">{{ item }}</li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
      <!--   预览工牌-->
      <el-dialog title="预览工牌" :visible.sync="isPreviewWorkCard" width="800px" append-to-body class="approvalWrap">
<!--        <section style="height: 400px;overflow: auto;text-indent:2em; font-size:14px;">-->
          <el-image style="border-radius: 10px;max-width: 100%;max-height:500px;overflow: auto;"
                    :src="preViewImageUrl"
                    :fit="fit">
            <div slot="error" class="image-slot"
                 style="width: 210px;height: 130px;line-height:130px; text-align:center;border-radius: 10px;background: #F2F2F2;">
              <i class="el-icon-picture-outline"
                 style="color: #999999;font-size: 12px;">
                暂无工牌
              </i>
            </div>
          </el-image>
<!--        </section>-->
      </el-dialog>
    </el-container>
</template>

<script>
// import AddressCascader from '@/components/AddressCascader'
import {
  personalInfo,
  groupInfo,
  groupAdd,
  subscribeInfo,
  occupation,
  title,
  serviceUpload,
  direction,
  updataPersonalInfo,
  updataSubscribeInfo,
  groupList,
  recommenderOpenId,
  groupApply,
  getAddressApi,
  hospital
} from "@/api/frp/fansManagement"
import { DEEP_CLONE } from '@/utils/constants'
import UserAgreement from "../../login/components/UserAgreement";
export default {
  name: "fansInformationEditing",
  // components: {
  //   AddressCascader
  // },
  data() {
    return {
      preViewImageUrl:'',
      isPreviewWorkCard: false,
      isHistoryGroupInfo: false,
      historyShowBean: {},
      hospitalList: [],
      groupInfoTitle: '',
      isPersonInfo: false,
      isExchange: false,
      isFansInfo: false,
      formAddGroupInfo: {
        entryTime: '',
        groupId: '',
        wxUserId: ''
      },
      // 表格高度
      tableHeight: null,
      // fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      fit: 'fill',
      // form: {
      //   name: '',
      //   region: '',
      //   date1: '',
      //   date2: '',
      //   delivery: false,
      //   type: [],
      //   resource: '',
      //   desc: '',
      //   selectedOptions: [],//地区筛选数组
      // },
      imageUrl: '',
      labelPosition: 'right',
      fansInfoForm: {
        id: '',
        openId: '',
        nickName: '',
        recommenderName: '',
        recommenderOpenId: '',
        subscribe: '',
        subscribeTime: '',
        unsubscribeTime: '',
        wxNumber: ''
      },
      applyList: [],
      groupInfoList: [],
      recommenderOpenIdList: [],
      isResult: false,
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      region: [],
      addressList: [], // 级联列表
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          let TYPE_LIST = [1,2,3]
          const { level, data } = node;
          const params = !data ? {
            currentLevel: TYPE_LIST[level],
            parentCode: '',
          }: {
            currentLevel: TYPE_LIST[level],
            parentCode: data.value,
          };
          const { data: res } = await getAddressApi(params);
          if (res) {
            const nodes = res.map(({code, name}) => ({
              leaf: node.level >= 2,
              value: code,
              label: name,
            }));
            resolve(nodes);
          }
        }
      },
      editBean:{
        cityCode: '',
        districtCode: '',
        hospitalCode: '',
        id: '',
        mainDirectionIdList: [],
        occupationId: '',
        provinceCode: '',
        realName: '',
        remark: '',
        titleId: '',
        workCardUrl: ''
      }, // 编辑个人信息
      originShowBean: {}, // 显示个人信息
      occupationList: [],
      titleList: [],
      fansInfo: {}, // 粉丝信息
      isAddGroupInfo: false, // 添加群信息
      isRefuseReason: false, // 拒绝原因
      formRefuseReason: {
        remark: '',
      },
      isAntiInfection: false,
      isLeaveGroup: false,
      formLeaveGroup: {
        groupStatus: '',
        time: '',
        remark: ''
      },
      openId: '',
      directionList: [],
      workCardUrl: '' ,
      isAddShow: false,
      singleId:'',//群信息单条数据ID
      refuseReasonId: '', // 拒绝Id
      leaveGroupId: '' // 退群Id
    }
  },
  filters: {
    subscribeChoice(val) {
      if (val === '0') {
        return '取消关注'
      } else if (val === '1') {
        return '关注中'
      } else {
        return '未知'
      }
    }
  },
  created() {
    // this.getList() // list
    // this.getServiceList() // 服务包list
    this.$nextTick(() => {
      // console.log(111)
      this.setTableHeight(1)
    })
    const id = this.$route.params && this.$route.params.id
    this.id = id
    if (this.id) {
      this.getPersonalInfo(this.id)
      this.getGroupInfo(this.id)
      this.getSubscribeInfo(this.id)
    } else {
      // console.log('id暂无')
    }
    this.getOccupation()
    this.getTitle()
    this.getDirection()
    // this.groupListFn()
    this.recommenderOpenIdFn()
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight(2)
  },
  mounted() {
    // 获取高度值 （内容高+padding+边框）
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      _this.setTableHeight(3)
    }

  },
  methods: {
    // 远程搜索获取医院list
    async remoteMethodSL (query) {
      if (query !== '') {
        this.loading = true
        // let queryData = {}
        // queryData.queryStr = query
        let res = await hospital(query)
        if (res.code === 200) {
          this.loading = false
          this.hospitalList = res.data
        }
      } else {
        this.hospitalList = []
      }
    },
    // 修改个人信息
    motifyPersonInfoClick() {
      this.isPersonInfo = true
    },
    // 取消修改个人信息
    async cancelPersonalInfoFn() {
      await this.getPersonalInfo(this.id)
      this.isPersonInfo = false
      this.isExchange = false
    },
    // 添加群信息
    addGroupInfo() {
      this.groupInfoTitle = '添加群信息'
      this.isAddGroupInfo = true
      this.isAddShow = false
      this.formAddGroupInfo = {
        entryTime: '',
          groupId: '',
          wxUserId: ''
      }
      this.groupListFn(1) // 添加
    },
    // 进群信息
    enterGroupInfo(row) {
      this.groupInfoTitle = '进群信息'
      this.isAddGroupInfo = true
      this.formAddGroupInfo.groupId = row.groupId
      this.formAddGroupInfo.entryTime = row.entryTime
      this.singleId = row.id
      this.isAddShow = true
      this.groupListFn(2) // 进群
    },
    // 通过进群信息
    passGroupInfo(row) {
      const id = row.id || ''
      // 是否确认删除字典编码为"' + dictCodes + '"的数据项?
      this.$confirm('是否确认通过该条数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        let obj = {}
        obj.groupStatus = 2
        obj.id = id
        return groupApply(obj);
      }).then(() => {
        this.getGroupInfo(this.id)
        this.msgSuccess("通过成功！");
      })
    },
    addressChange(code) {
      // console.log(code)
    },
    // 个人信息查询
    async getPersonalInfo(id) {
      let res = await personalInfo(id)
      if(res.code === 200) {
        if (res.data.originShowBean) {
          this.originShowBean = res.data.originShowBean
          // process.env.VUE_APP_WEIXIN_SERVER +
          this.originShowBean.workCardUrl = process.env.VUE_APP_BASE_API +"/thys-api" + res.data.originShowBean.workCardUrl
        }
        this.editBean = res.data.editBean
        // editBean.provinceName }} {{ editBean.cityName }} {{ editBean.districtName }}
        this.region = []
        this.region[0] = this.editBean.provinceCode
        this.region[1] = this.editBean.cityCode
        this.region[2] = this.editBean.districtCode
        this.editBean.workCardUrl =  process.env.VUE_APP_BASE_API + "/thys-api" + res.data.editBean.workCardUrl
        this.historyShowBean = res.data.historyShowBean
      }
    },
    // 个人信息更新
    async updataPersonalInfoFn() {
      let obj = {}
      obj.provinceCode = this.region[0]
      obj.cityCode = this.region[1]
      obj.districtCode = this.region[2]
      obj.hospitalCode = this.editBean.hospitalCode
      obj.id = this.editBean.id
      obj.mainDirectionIdList = this.editBean.mainDirectionIdList
      obj.occupationId = this.editBean.occupationId
      obj.realName = this.editBean.realName
      obj.remark = this.editBean.remark
      obj.titleId = this.editBean.titleId
      obj.workCardUrl = this.workCardUrl
      let res = await updataPersonalInfo(obj)
      if(res.code === 200) {
        this.isPersonInfo = false
        this.getPersonalInfo(this.id)
        this.msgSuccess("个人信息修改成功！");
      }
    },
    // 群信息查询
    async getGroupInfo(id) {
      this.loading = true
      let res = await groupInfo(id)
      if(res.code === 200) {
        this.applyList = res.data
        this.loading = false
      }
    },
    // groupList
    async groupListFn(val) {
      let obj = {}
      if (val === 1) { // 添加群信息
        obj.type = 1
      } else if(val === 2) { // 进群
        obj.type = 3
      }
      let res = await groupList(obj)
      if(res.code === 200) {
        this.groupInfoList = res.data
      }
    },
    // recommenderOpenId
    async recommenderOpenIdFn() {
      let res = await recommenderOpenId()
      if(res.code === 200) {
        this.recommenderOpenIdList = res.data
      }
    },
    // groupAdd
    async groupAddFn() {
      if (this.groupInfoTitle === '添加群信息') {
        let obj = {}
        obj.wxUserId = this.id
        obj.entryTime = this.formAddGroupInfo.entryTime
        obj.groupId = this.formAddGroupInfo.groupId
        let res = await groupAdd(obj)
        if(res.code === 200) {
          this.msgSuccess("进群成功！");
          this.isAddGroupInfo = false
          this.getGroupInfo(this.id)
        }
      } else if (this.groupInfoTitle === '进群信息') {
        let obj = {}
        obj.groupStatus = 6
        obj.entryTime = this.formAddGroupInfo.entryTime
        obj.id = this.singleId
        let res = await groupApply(obj)
        if(res.code === 200) {
          this.msgSuccess("进群成功！");
          this.isAddGroupInfo = false
          this.getGroupInfo(this.id)
        }
      }

    },
    // 粉丝关注信息查询
    async getSubscribeInfo(id) {
      let res = await subscribeInfo(id)
      if(res.code === 200) {
        this.fansInfoForm = res.data
        if (res.data.subscribe === true) {
          this.fansInfoForm.subscribe = '1'
        } else if (res.data.subscribe === false) {
          this.fansInfoForm.subscribe = '0'
        } else {
          this.fansInfoForm.subscribe = ''
        }
        this.openId = res.data.openId
      }
    },
    //取消粉丝修改
    async cancelSubscribeInfoFn() {
      await this.getSubscribeInfo(this.id)
      this.isFansInfo = false
    },
    // 修改粉丝信息
    motifyFansInfo(){
      this.isFansInfo = true
    },
    // 粉丝关注信息更新
    async updataSubscribeInfoFn() {
      let obj = {}
      obj.id = this.fansInfoForm.id
      obj.nickName = this.fansInfoForm.nickName
      obj.recommenderOpenId = this.fansInfoForm.recommenderOpenId
      obj.wxNumber = this.fansInfoForm.wxNumber
      let res = await updataSubscribeInfo(obj)
      if(res.code === 200) {
        this.isFansInfo = false
        this.getSubscribeInfo(this.id)
        this.msgSuccess("粉丝关注信息修改成功！");
      }
    },
    // 职业列表list
    async getOccupation() {
      let res = await occupation()
      if(res.code === 200) {
        this.occupationList = res.data
      }
    },
    // 粉丝关注信息查询
    async getTitle() {
      let res = await title()
      if(res.code === 200) {
        this.titleList = res.data
      }
    },
    // 主攻方向查询
    async getDirection() {
      let res = await direction()
      if(res.code === 200) {
        this.directionList = res.data
      }
    },
    /*设置表格的高度*/
    setTableHeight(val){
      // console.log(val)
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      // console.log(formHeight)
      let parentClientWidth = this.$refs.barparent.$el.offsetHeight;
      // console.log(parentClientWidth)
      this.tableHeight = parentClientWidth - 30 - 30
    },
    exchangeImg() {
      this.isExchange = true
    },
    // 上传文件
    uploadFile (item) {
      const fileObj = item.file
      // // FormData 对象
      const form = new FormData()
      // // 文件对象
      form.append('file', fileObj)
      form.append('bucket', this.openId + '/edit')
      form.append('key', item.file.name)
      serviceUpload(form).then(res => {
        if (res.code === 200) {
          this.editBean.workCardUrl =   process.env.VUE_APP_BASE_API + "/thys-api" + res.data
          this.workCardUrl = res.data
          this.isExchange = false
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      // console.log(file)
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt2M;
    },
    // 拒绝原因
    refuseReasonClick(row) {
      this.isRefuseReason = true
      this.refuseReasonId = row.id
    },
    // 撤销通过
    reBackGroupInfo(row) {
      const id = row.id || ''
      // 是否确认删除字典编码为"' + dictCodes + '"的数据项?
      this.$confirm('是否确认撤销该条数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        let obj = {}
        obj.groupStatus = 1
        obj.id = id
        return groupApply(obj);
      }).then(() => {
        this.getGroupInfo(this.id)
        this.msgSuccess("撤销成功！");
      })
    },
    async refuseReasonConfirm() {
      let obj = {}
      obj.groupStatus = 3
      obj.id = this.refuseReasonId
      obj.remark = this.formRefuseReason.remark
      let res = await groupApply(obj)
      if(res.code === 200) {
        this.msgSuccess("审核未通过！");
        this.isRefuseReason = false
        this.getGroupInfo(this.id)
      }
    },
    // 退群
    leaveGroupClick(row) {
      this.isLeaveGroup = true
      this.leaveGroupId = row.id
    },
    async groupApplyFn() {
      let obj = {}
      obj.signOutTime = this.formLeaveGroup.signOutTime
      obj.groupStatus = this.formLeaveGroup.groupStatus
      obj.id = this.leaveGroupId
      obj.remark = this.formLeaveGroup.remark
      let res = await groupApply(obj)
      if(res.code === 200) {
        this.msgSuccess("退群成功！");
        this.isLeaveGroup = false
        this.getGroupInfo(this.id)
      }
    },
    lookAntiInfectionCaseFn() {
      this.isAntiInfection = true
    },
    // 查看个人信息
    async lookGroupInfoClick() {
      this.isHistoryGroupInfo = true
      // this.historyShowBean = res.data.historyShowBean
    },
    // 预览图片
    previewWorkCardClick(preViewImageUrl) {
      console.log(preViewImageUrl)
      this.isPreviewWorkCard = true
      this.preViewImageUrl = preViewImageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
  .fie_wrap{
    background-color: #EBEFF7;
    display: flex;
    //width: 100%;
    height: 100%;
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 210px;
      height: 130px;
      line-height: 130px;
      text-align: center;
      border:1px dashed #999999;
    }
    .avatar {
      width: 210px;
      height: 130px;
      display: block;
    }
    aside{
      padding: 0px!important;
      margin:0px!important;
    }
    main{
      padding: 0px!important;
      margin:0px!important;
    }
    header{
      padding: 0px!important;
      margin:0px!important;
    }
    .fie_left{
      width: 50% !important;
      min-width: 500px!important;
      padding: 0!important;
      margin: 10px 5px 0px 10px!important;
      box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
      border-radius: 8px;
      background: #FFFFFF;
      overflow: auto;
      .fie_left_top{
        height: 540px;
        //overflow: auto;
        display: flex;
        //flex-wrap: wrap;
        justify-content: space-between;
        //align-items: flex-start;
        .fie_left_top_left{
          border-right: 1px dashed #DBE1FF;
          border-bottom: 1px dashed #DBE1FF;
          min-width: 380px!important;
          background: #FFFFFF;
          width: 50%;
          padding: 10px!important;
          .personalInfo{
            width: 100%;
            padding:3px 0px;
            .personalInfo_label{
              text-align: right;
              font-size: 14px;
              font-weight: 400;
              color: #888888;
            }
            .personalInfo_content{
              text-indent: 3px;
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              .ipAddress{
                display: inline-block;
                text-indent: 3px;
                font-size: 14px;
                font-weight: 400;
                color: #888888;
              }
              .zgfx_ul{
                float: left;
                .zgfx_li{
                  display: inline-block;
                  //width: 50px;
                  padding:0px 5px;
                  height: 30px;
                  line-height: 30px;
                  background: #FAEBFF;
                  border: 1px dashed #CF75EB;
                  text-align: center;
                  border-radius: 4px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #CF75EB;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .fie_left_top_right{
          border-bottom: 1px dashed #DBE1FF;
          min-width: 380px!important;
          padding: 10px!important;
          .zgfx_ul{
            float: left;
            .zgfx_li{
              display: inline-block;
              //width: 50px;
              padding:0px 5px;
              height: 30px;
              line-height: 30px;
              background: #FAEBFF;
              border: 1px dashed #CF75EB;
              text-align: center;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 400;
              color: #CF75EB;
              margin-right: 5px;
            }
          }
          //width: 49%;
          .personalInfo{
            width: 100%;
            padding:3px 0px;
            .personalInfo_label{
              text-align: right;
              font-size: 14px;
              font-weight: 400;
              color: #888888;
            }
            .personalInfo_content{
              text-indent: 3px;
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              .ipAddress{
                display: inline-block;
                text-indent: 3px;
                font-size: 14px;
                font-weight: 400;
                color: #888888;
              }
            }
          }
        }
      }
      .fie_left_bottom{
        padding: 10px!important;
        overflow: auto;
        height: auto!important;
      }
    }
    .fie_right{
      flex: 1;
      //background: #FFFFFF;
      //border-radius: 8px;
      //margin: 10px 10px 0px 5px!important;
      padding: 0 0px 0px 0px !important;
      //box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
      .fie_right_top{
        //min-width: 600px!important;
        background: #FFFFFF;
        border-radius: 8px;
        margin: 10px 10px 0px 5px!important;
        box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
        height:40%!important;
        overflow: auto;
        padding:10px!important;
        .fie_right_top_section{
          min-width: 600px;
          overflow: auto;
          .personalInfo_content{
            display: block;
            text-indent: 3px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            .focusStatus{
              //display: block;
              padding: 5px;
              font-size: 14px!important;
              font-weight: 400;
              color: #6CCD3B!important;
              height: 26px;
              background: #E9FADE;
              border-radius: 4px;
            }
          }
        }
      }
      .fie_right_bottom{
        background: #FFFFFF;
        border-radius: 8px;
        margin: 10px 10px 0px 5px!important;
        box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
        padding:5px!important;
        //display: flex!important;
        .fie_right_bottom_section{
          //position: relative;
          .fie_right_bottom_header{
            height: 30px!important;
            line-height: 30px;
            margin-bottom: 10px!important;
          }
          .fie_right_bottom_main{
            background: #ffcc66;
          }
          .fie_right_bottom_footer{
            //position: absolute;
            //left: 0;
            //bottom: 0;
            height: 40px!important;
            padding-top: 5px;
            .el-pagination{
              height: 30px!important;
              //line-height: 60px!important;
              text-align: right;
            }

          }
        }
      }
    }
  }
</style>
<style lang="scss">
.fie_wrap{
  .el-form-item {
    margin-bottom: 2px;
  }
  .el-form-item__label{
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: #888888;
  }
}
</style>

